* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 10px;
}
body{
  margin: 0;
  padding: 0;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5px;
  background: #2071EB;
  transform-origin: 0%;
  z-index: 20;
}
