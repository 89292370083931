.body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin-top: 10rem;
  margin-bottom: 5rem;
}

.container {
  position: relative;
  width: 600px;
  height: 600px;
  border: 2px solid #2196f3;
  border-radius: 50%;
  z-index: 1;
}

.container .icon {
  position: relative;
  left: -50%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.container .icon .imgBx {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  transition: 0.5s;
  border: 1px solid #444;
  box-shadow: 0 0 0 4px #fff, 0 0 0 6px #2196f3;
  transform: rotate(calc(360deg / 11 * var(--i)));
  transform-origin: 340px;
  z-index: 100;
  overflow: hidden;
  /* animation: animate_03 1s linear infinite backwards; */
}
.container .icon .imgBx.active {
  box-shadow: 0 0 0 6px #ff1d50, 0 0 0 6px #ff1d50;
}
.container .icon .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transform: rotate(calc(-360deg / 11 * var(--i)));
  transform: 0.5s;
  filter: grayscale(1);
}
.container .icon .imgBx.active img {
  filter: grayscale(0);
}
.content {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content::before {
  content: "";
  position: absolute;
  inset: 60px;
  border: 4px solid transparent;
  border-left: 4px solid #2196f3;
  border-right: 4px solid #222;
  border-radius: 50%;
  z-index: 1;
  pointer-events: none;
  animation: animate_01 5s linear infinite forwards;
}

@keyframes animate_01 {
  0% {
    rotate: 0deg;
  }
  100% {
    rotate: 360deg;
  }
}
@keyframes animate_03 {
  0% {
    border-radius: 50%;
  }
  100% {
    border-radius: 0%;
  }
}
.content::after {
  content: "";
  position: absolute;
  inset: 120px;
  border: 4px solid transparent;
  border-left: 4px solid #ff1d50;
  border-right: 4px solid #444;
  border-radius: 50%;
  animation: animate_02 2.5s linear infinite;
  z-index: 1;
  pointer-events: none;
}

@keyframes animate_02 {
  0% {
    rotate: 360deg;
  }
  100% {
    rotate: 0deg;
  }
}

.contentBx {
  position: absolute;
  transform: scale(0);
  transition: 0.5s;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentBx.active {
  transform: scale(1);
  opacity: 1;
  transition-delay: 0.5s;
}

.contentBx .card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.contentBx .card .imgBx {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
}

.contentBx .card .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contentBx .card .textBx {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contentBx .card .textBx h2 {
  position: relative;
  font-size: 2rem;
  font-weight: 600;
  color: #222;
  line-height: 2rem;
  text-transform: uppercase;
  text-align: center;
  font-family: "Poppins";
}
.contentBx .card .textBx h2 span {
  font-size: 1.6rem;
  color: #444;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 1rem;
  font-family: "Poppins";
}

.contentBx .card .textBx .sci {
  position: relative;
  display: flex;
  gap: 7px;
  margin-top: 10px;
}

.contentBx .card .textBx .sci li {
  list-style: none;
}

.contentBx .card .textBx .sci li a {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: 0.5s;
}
.contentBx .card .textBx .sci li a:hover {
  background: #2196f3;
}

@media (max-width: 600px) {
  .body {
    margin-top: 10rem;
    margin-bottom: 15rem;
  }

  .container {
    position: relative;
    width: 300px;
    height: 300px;
  }

  .container .icon {
    position: relative;
    left: -50%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .container .icon .imgBx {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: 0.5s;
    border: 1px solid #444;
    box-shadow: 0 0 0 2px #fff, 0 0 0 6px #2196f3;
    transform: rotate(calc(360deg / 8 * var(--i)));
    transform-origin: 175px;
    z-index: 100;
    overflow: hidden;
  }
  .container .icon .imgBx.active {
    box-shadow: 0 0 0 3px #ff1d50, 0 0 0 3px #ff1d50;
  }
  .container .icon .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    transform: rotate(calc(-360deg / 8 * var(--i)));
    transform: 0.5s;
    filter: grayscale(1);
  }
  .container .icon .imgBx.active img {
    filter: grayscale(0);
  }
  .content {
    position: absolute;
    inset: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .content::before {
    content: "";
    position: absolute;
    inset: 25px;
    border: 2px solid transparent;
    border-left: 2px solid #2196f3;
    border-right: 2px solid #222;
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
    animation: animate_01 5s linear infinite forwards;
  }

  @keyframes animate_01 {
    0% {
      rotate: 0deg;
    }
    100% {
      rotate: 360deg;
    }
  }
  @keyframes animate_03 {
    0% {
      border-radius: 50%;
    }
    100% {
      border-radius: 0%;
    }
  }
  .content::after {
    content: "";
    position: absolute;
    inset: 10px;
    border: 2px solid transparent;
    border-left: 2px solid #ff1d50;
    border-right: 2px solid #444;
    border-radius: 50%;
    animation: animate_02 2.5s linear infinite;
    z-index: 1;
    pointer-events: none;
  }

  @keyframes animate_02 {
    0% {
      rotate: 360deg;
    }
    100% {
      rotate: 0deg;
    }
  }

  .contentBx {
    position: absolute;
    transform: scale(0);
    transition: 0.5s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contentBx.active {
    transform: scale(1);
    opacity: 1;
    transition-delay: 0.5s;
  }

  .contentBx .card {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
  }

  .contentBx .card .imgBx {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
  }

  .contentBx .card .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .contentBx .card .textBx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .contentBx .card .textBx h2 {
    position: relative;
    font-size: 1.6rem;
    font-weight: 600;
    color: #222;
    line-height: 1.6rem;
    text-transform: uppercase;
    text-align: center;
    font-family: "Poppins";
  }
  .contentBx .card .textBx h2 span {
    font-size: 1.3rem;
    color: #444;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1rem;
    font-family: "Poppins";
  }

  .contentBx .card .textBx .sci {
    position: relative;
    display: flex;
    gap: 5px;
    margin-top: 5px;
  }

  .contentBx .card .textBx .sci li {
    list-style: none;
  }

  .contentBx .card .textBx .sci li a {
    position: relative;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.5s;
  }
  .contentBx .card .textBx .sci li a:hover {
    background: #2196f3;
  }
}
