.footer {
  // margin-top: 25rem;
  position: relative;
  .footer-inside {
    width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 10rem;

    .footer-content {
      // margin: 2.8rem 0 7rem 7rem;

      .footer-links {
        display: flex;
        flex-direction: column;
        .footer-logo-location {
          img {
            margin-right: 4rem;
            width: 8rem;
            height: 8rem;
          }

          .footer-location {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            a {
              font-size: 2rem;
              text-decoration: none;
              color: #fff;
              margin-left: 5rem;
              margin-top: 0rem;
            }
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
              font-family: "Poppins";
              font-style: normal;
              font-weight: 600;
              font-size: 2.6rem;
              line-height: 4rem;
              color: #fff;
              margin-left: -7rem;
            }
          }
        }

        margin-top: 9.2rem;
        display: flex;
        justify-content: space-between;

        .footer-link {
          position: relative;
          h5 {
            font-weight: 600;
            font-size: 2.6rem;
            line-height: 4.5rem;
            color: #fff;
          }

          div {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            a {
              margin-top: 1.3rem;
              text-decoration: none;
              font-size: 2rem;
              color: #fff;
              // position: relative; /* Required to position the underline properly */
            
              // &:hover {
              //   text-decoration: none; /* Remove default text decoration on hover */
              //   &::after {
              //     content: '';
              //     position: absolute;
              //     left: 0;
              //     bottom: 0;
              //     width: 50%;
              //     height: 3px; /* Adjust the height of the underline as needed */
              //     background: white; /* Adjust the color of the underline as needed */
              //     animation: underlineAnimation 1s backwards; /* Duration and animation name for the underline */
              //   }
              // }
            }
            
            @keyframes underlineAnimation {
              to {
                width: 0; /* Animating the width to create an underline effect */
              }
            }            
          }
        }
      }

      .footer-subscribe {
        div {
          display: block;
        }

        h5 {
          font-weight: 600;
          font-size: 2rem;
          line-height: 4.5rem;
        }

        .footer-links-subscribe {
          margin-top: 1.2rem;

          .footer-input {
            height: 5rem;
            width: 90%;
            background-color: #e9f4fa;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 1.1rem;

            input {
              text-decoration: none;
              width: 31rem;
              height: 4.5rem;
              border-width: 0;
              border: none;
              background-color: #e9f4fa;
              margin: 1.3rem 1rem 1.3rem 2.7rem;
              font-size: 2rem;
            }

            input:focus {
              outline: none;
              border: none;
            }

            button {
              margin: 2rem 2rem 2rem auto;
              border: none;
              background-color: transparent;

              img {
                width: 2.5rem;
                height: 2.6rem;
                margin-left: -1rem;
              }

              :hover {
                cursor: pointer;
              }
            }
          }

          .footer-socialmedia-links {
            margin-top: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            a {
              margin-right: 2.1rem;
              img {
                width: 5.2rem;
                height: 5.2rem;
                background-color: #fff;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .footer-border {
      border: 2px solid #fff;
      opacity: 0.32;
      margin-top: 5rem;
    }

    .footer-copy-rights {
      display: flex;
      height: 5rem;
      justify-content: center;
      font-size: 2rem;
      align-items: center;
      
      a {
        font-weight: 400;
        font-size: 2rem;
        color: #fff;
      }

      img {
        margin-right: 1.2rem;
        width: 2rem;
        height: 2rem;
        background-color: #fff;
        border-radius: 50%;
      }
    }
  }
}

.footer-more {
  position: relative;
}

.footer-playStoreButton {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: -2rem;
  margin-right: 9rem;
  a {
    margin-right: 5rem;
    img {
      width: 15rem;
      height: 5rem;
    }
  }
}

@media (max-width: 600px) {
  .footer {
    .footer-inside {
      width: auto;
      display: block;
      // margin: 2rem 0rem;

      .footer-content {
        flex-direction: column;
        justify-content: start;
        margin: 0 0;

        .footer-links {
          margin-right: 0;
          justify-content: space-between;

          .footer-logo-location {
            .footer-location {
              display: flex;
              justify-content: flex-start;
              flex-direction: column;

              a {
                padding-top: 0;
                font-size: 2.2rem;
                line-height: 3rem;
                width: auto;
                text-decoration: none;
                margin-left: 0;
                margin-top: auto;
              }
            }

            a {
              img {
                width: 10rem;
                height: 10rem;
                z-index: -1;
                margin-left: -1rem;
              }
              // p{
              //   margin
              // }
            }
          }

          margin-top: 0;
          width: auto;

          .footer-link {
            h5 {
              font-weight: 600;
              font-size: 2.6rem;
              line-height: auto;
            }

            div {
              margin-top: 0;

              a {
                margin-top: 0;
                text-decoration: none;
                font-size: 2rem;
              }
              p{
                margin-left: -10rem;
              }
            }
          }
        }

        .footer-subscribe {
          margin-top: 3.5rem;
          width: 100%;
          div {
            display: block;
          }

          h5 {
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2rem;
          }

          .footer-links-subscribe {
            display: flex;
            margin-top: 1rem;

            .footer-input {
              height: 5.13rem;
              width: 90%;
              border-radius: 1.1rem;
              justify-content: start;
              margin-top: 1rem;

              input {
                width: 100%;
                height: auto;
                margin: 1.3rem 1rem 1.3rem 2.7rem;
                font-size: 1.4rem;
              }

              button {
                margin: 0 2rem 0 0;
                border: none;
                background-color: transparent;
                width: auto;

                img {
                  transform: translateY(0.3rem);
                  width: 1.927rem;
                  height: 1.927rem;
                }
              }
            }

            .footer-socialmedia-links {
              margin-top: 3rem;
              // justify-content: space-between;
              // margin-bottom: 2rem;
              margin-left: 0rem;

              a {
                img {
                  width: 5.6rem;
                  height: 5.6rem;
                }
              }
            }
          }
        }
      }

      .footer-copy-rights {
        font-size: 1.6rem;
        // margin: 3rem auto;

        img {
          margin-right: 1.2rem;
          width: 2.6rem;
          height: 2.6rem;
        }
        a {
          font-weight: 400;
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media (max-width: 899px) {
  .footer-playStoreButton {
    display: flex;
    justify-content: flex-start;
    margin-top: -6rem;
    margin-left: 3rem;

    a {
      img {
        width: 13rem;
        height: 4rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer-playStoreButton {
    // position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 2rem;
    a {
      img {
        width: 13rem;
        height: 4rem;
      }
    }
  }
}
