
.newcard
{
    position: relative;
    width: 350px;
    height: 190px;
    background: #fff;
    transition: 0.5s;
}
.newcard:hover
{
    height: 450px;
}
.newcard .newlines
{
    position: absolute;
    inset: 0;
    background: #219FD5;
    overflow: hidden;
}
.newcard .newlines::before
{
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 120px;
    background-image: linear-gradient(transparent,#000,#000,#000);
    animation: newanimate 4s linear infinite;
}
@keyframes newanimate 
{
    0%    
    {
        transform: translate(-50%,-50%) rotate(0deg);
    }
    100%    
    {
        transform: translate(-50%,-50%) rotate(360deg);
    }
}
.newcard .newlines::after
{
    content: ' ';
    position: absolute;
    inset: 3px;
    background: #fff;
}
.newcard .newimgBox
{
    position: absolute;
    top: -50px;
    left: 50%;
    transform: translate(-50%);
    width: 150px;
    height: 150px;
    background: #000;
    transition: 0.5s;
    z-index: 10;
    overflow: hidden;
}
.newcard:hover .newimgBox
{
    width: 250px;
    height: 250px;
}
.newcard .newimgBox::before
{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 500px;
    height: 150px;
    transform: translate(-50%,-50%);
    background-image: linear-gradient(transparent,#ff3c7b,#ff3c7b,#ff3c7b,transparent);
    animation: newanimate2 6s linear infinite;
}
@keyframes newanimate2 
{
    0%    
    {
        transform: translate(-50%,-50%) rotate(360deg);
    }
    100%    
    {
        transform: translate(-50%,-50%) rotate(0deg);
    } 
}
.newcard .newimgBox::after
{
    content: '';
    position:absolute;
    inset: 3px;
    background: #292929;
}
.newcard .newimgBox img
{
    position: absolute;
    top: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
    z-index: 1;
    width: 95%;
    height: 95%;
}
.newcard .newContent
{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
}
.newcard .newContent .newdetail
{
    padding: 20px;
    text-align: center;
    width: 100%;
    transition: 0.5s;
    transform: translateY(140px);
}
.newcard:hover .newContent .newdetail
{
    transform: translateY(0px);
}
.newcard .newContent .newdetail h2
{
    font-size: 2.2rem;
    font-weight: 600;
    color: #000;
    line-height: 1.2em;
}

.newcard .newContent .newdetail .newdata
{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.newcard .newContent .newdetail h3
{
    font-size: 1.6rem;
    color: #000;
    line-height: 1.2em;
    font-weight: 600;
}
.newcard .newContent .newdetail .newdata h3 span
{
    font-size: 0.85em;
    font-weight: 400;
    color: #fff;
}
.newcard .newContent .newdetail .newaction
{
    display: flex;
    justify-content: center;
}
.newcard .newContent .newdetail .newaction button
{
    padding: 10px 30px;
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 1.6em;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}
.newcard .newContent .newdetail .newaction button:nth-child(2)
{
    background: #fff;
}